<div class="background-image-container">
  <div class="container">
    <div class="jumbotron jumbotron-fluid">
      <div class="d-flex flex-wrap">
        <div>
          <h1 class="display-3">Repositorio UNIA</h1>
          <p class="lead">Repositorio Institucional: Tesis, Libros y más...</p>
        </div>
      </div>
    </div>
  </div>
  <picture class="background-image">
    <source type="image/webp" srcset="assets/unia/images/banner.webp 2000w, assets/unia/images/banner.webp 1200w, assets/unia/images/banner.webp 768w">
    <source type="image/jpg" srcset="assets/unia/images/banner.jpg 2000w, assets/unia/images/banner.jpg 1200w, assets/unia/images/banner.jpg 768w">
    <img alt="" [src]="'assets/unia/images/banner.jpg'"/><!-- without the []="''" Firefox downloads both the fallback and the resolved image -->
  </picture>
  <small class="credits">Foto por <a href="#">@imagen</a></small>
</div>
